<template>
	<section :class="isTabs ? 'list-tabs' : 'list-categories'">
		<TnHeading
			v-if="header"
			type="h3"
			class="padding-bottom-m"
			>{{ header }}</TnHeading
		>
		<LinkWrap
			v-for="(link, index) in links"
			v-bind="link"
			:key="`link-${index}`"
			class="margin-s"
			:class="[
				{
					active:
						isTabs &&
						((link.to && link.to.indexOf(getActivePage) > -1) ||
							(link.to && link.to.split('?')[0].endsWith(getActiveDirectory))),
				},
				{ loading },
				color,
			]"
		>
			{{ link.text }}
		</LinkWrap>
	</section>
</template>

<script>
import LinkViewModel from "../../../../helpers/ViewModels/LinkViewModel.js";
import { usePageStore } from "~/pinia/platform/page.ts";
import { mapState } from "pinia";
export default defineNuxtComponent({
	name: "ListCategories",

	props: {
		component: { type: Object },
		loading: { type: Boolean, default: false },
	},

	computed: {
		...mapState(usePageStore, ["page"]),
		header() {
			return this.component.content.header;
		},
		color() {
			return this.component.content.color;
		},
		isTabs() {
			return this.component.schema?._id.endsWith("list--tabs");
		},
		links() {
			if (this.loading) {
				return [];
			} else {
				return this.component.content.link
					.map((link) => {
						return LinkViewModel(link);
					})
					.filter((link) => {
						return link && link.text && (link.href || link.to);
					});
			}
		},
		getActivePage() {
			let pageLink = this.page.publishUrl || "";
			pageLink = pageLink.split(".").shift().concat(".");
			return pageLink;
		},
		getActiveDirectory() {
			return this.page.directoryUrl;
		},
	},
});
</script>

<style lang="scss" scoped>
.list-tabs {
	width: 100%;
	min-width: 100%;

	a {
		display: inline-block;
		color: black;
		text-align: center;
		padding: $spacing-none $spacing-l $spacing-none $spacing-l;
		height: $spacing-2xl;
		min-width: 128px;
		text-decoration: none;
		outline: none;
		border-radius: $spacing-l;
		border: 1px solid $color-neutrals-200-tint;

		@include font-text-s;

		@keyframes loading-placeholder {
			0% {
				transform: translateX(-120%);
			}

			100% {
				transform: translateX(100%);
			}
		}

		&:hover {
			background-color: $color-neutrals-25-tint;
		}

		&.active {
			background-color: $color-background-blue;
			border-color: $color-information-500-core;
		}

		&.loading {
			position: relative;
			overflow: hidden;

			&::after {
				content: "";
				display: block;
				background-color: rgb(0 0 0 / 5%);
				position: absolute;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				transform: translateX(-100%);
				animation: 1.5s loading-placeholder ease-in-out infinite;
			}
		}

		@include breakpoint(mobile) {
			height: 44px;
			min-width: 72px;
			padding-left: $spacing-s;
			padding-right: $spacing-s;
		}
	}
}

.list-categories {
	margin: -$spacing-s;

	a {
		display: inline-block;
		position: relative;
		padding: $spacing-s $spacing-m;
		border-radius: 8px;

		@include font-text-l;
		@include shadow(m);

		&::before {
			content: "";
			background: black;
			position: absolute;
			inset: 0;
			opacity: 0;
			border-radius: 8px;
			transition: opacity 0.3s ease-out;
		}

		&:hover {
			&::before {
				opacity: 0.05;
			}
		}

		&.white {
			background-color: $color-background-white;
		}

		&.blue {
			background-color: $color-background-blue;
		}
	}
}
</style>
